import React from 'react'
import bottomTitle from '../assets/img/prestations.svg'
import '../assets/css/prestations.css'
import logoTop from '../assets/img/logo.svg'
import { Helmet } from 'react-helmet-async'
function Prestations() {
  const bTitle = () => {
    document.getElementById('bot-title').style.bottom = '0'
  }
  const gridAnim = () => {
    document.getElementsByClassName('pr-content-wrap')[0].style.opacity = '1'
    document.getElementsByClassName('pr-content-menu')[0].style.opacity = '1'
  }

  return (
    <>
      <Helmet>
        <title>
          Mes prestations | MLgweb.fr | Création de sites internets et de logos.
        </title>
      </Helmet>
      <object
        type='image/svg+xml'
        data={logoTop}
        className='logo-top'
        onLoad={gridAnim}
      >
        svg
      </object>
      <div className='bottom-title' id='bot-title'>
        <object
          type='image/svg+xml'
          data={bottomTitle}
          className='bottom-title-img'
          onLoad={bTitle}
        >
          svg
        </object>
      </div>
      <div className='pr-content-wrap'>
        <div className='grid-container' id='grid1'>
          <div className='grid-title'>
            <span>Sites personnels et sites vitrines</span>
          </div>
          <div className='grid-content'>
            <p>
              <strong>
                Envie d’une place sur Internet pour partager vos compétences,
                vos passions ou promouvoir votre entreprise ?
              </strong>
            </p>
            <p>
              Après un entretien téléphonique pour déterminer vos besoins, je
              réaliserai un site internet adapté à vos exigences.
            </p>
            <ul>
              <li>Blog</li>
              <li>CV en ligne</li>
              <li>Portfolio</li>
              <li>Site vitrine pour votre entreprise</li>
              <li>...</li>
            </ul>
            <p>Tarifs : sur devis.</p>
            <p>
              <strong>
                <a href='/contact'>
                  Contactez-moi pour convenir d'un rendez-vous téléphonique.
                </a>
              </strong>
            </p>
          </div>
          <div className='grid-par'></div>
          <div className='grid-par2'></div>
          <div className='grid-par3'></div>
          <div className='grid-par4'></div>
          <div className='grid-par5'></div>
          <div className='grid-par6'></div>
        </div>
        <div className='grid-container' id='grid2'>
          <div className='grid-title'>Logos et chartes graphiques</div>
          <div className='grid-content'></div>
          <div className='grid-par'></div>
          <div className='grid-par2'></div>
          <div className='grid-par3'></div>
          <div className='grid-par4'></div>
          <div className='grid-par5'></div>
          <div className='grid-par6'></div>
        </div>
        {/* <div className="content-box">
          <div className="box-deco-l"></div>
          <div className="box">cool content</div>
          <div className="box-deco-r"></div>
        </div>
        <div className="content-box">
          <div className="box-deco-l"></div>
          <div className="box">cool content</div>
          <div className="box-deco-r"></div>
        </div> */}
      </div>
      <div className='pr-content-menu'>
        <ul>
          <li>
            <a href='#grid1'>Sites personnel et sites vitrines</a>
          </li>
          <li>
            <a href='#grid2'>Logos et chartes graphiques</a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Prestations
