import './App.css'
import Navigation from './components/navigation'
import Navbutton from './components/navbutton'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/home'
import Prestations from './components/prestations'
import References from './components/references'
import Contact from './components/contact'
import { Helmet, HelmetProvider } from 'react-helmet-async'

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name='robots' content='noindex'></meta>
        <title>MLgweb.fr | Création de sites internets et de logos</title>
      </Helmet>
      <Router>
        <Navbutton />
        <Navigation />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/prestations'>
            <Prestations />
          </Route>
          <Route path='/references'>
            <References />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
        </Switch>
      </Router>
    </HelmetProvider>
  )
}

export default App
