import React from 'react'
import logo from '../assets/img/logo_home.svg'
// import logob from '../assets/img/logo_home.svg'
import '../assets/css/home.css'



function Home() {
  
  return (
    <div className="home-wrapper">
      <object type="image/svg+xml" data={logo} className="home-logo">svg-animation</object>
    </div>
  )
}

export default Home
