import React from 'react'
import '../assets/css/contact.css'
import bottomTitle from '../assets/img/contact.svg'
import logoTop from '../assets/img/logo.svg'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      phone: '',
      email: '',
      name: '',
      errora: ['Tous les champs sont obligatoires.'],
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    axios({
      method: 'POST',
      url: 'https://www.mlgweb.fr/send/index.php',
      data: this.state,
    }).then((response) => {
      const erMessage = document.getElementById('message-er')
      const erName = document.getElementById('name-er')
      const erPhone = document.getElementById('phone-er')
      const erEmail = document.getElementById('email-er')
      if (response.data.status === 'success') {
        alert('Message envoyé.')
        this.resetForm()
        erMessage.style.display = 'none'
        erName.style.display = 'none'
        erPhone.style.display = 'none'
        erEmail.style.display = 'none'
      } else if (response.data.status === 'fail') {
        this.setState({ errora: response.data.error })
        this.state.errora.message
          ? (erMessage.style.display = 'block')
          : (erMessage.style.display = 'none')
        this.state.errora.name
          ? (erName.style.display = 'block')
          : (erName.style.display = 'none')
        this.state.errora.phone
          ? (erPhone.style.display = 'block')
          : (erPhone.style.display = 'none')
        this.state.errora.email
          ? (erEmail.style.display = 'block')
          : (erEmail.style.display = 'none')
      }
    })
  }
  resetForm() {
    this.setState({
      message: '',
      phone: '',
      email: '',
      name: '',
      errora: ['Tous les champs sont obligatoires.'],
    })
  }

  render() {
    const bTitle = () => {
      document.getElementById('bot-title').style.bottom = '0'
    }
    const gridAnim = () => {
      document.getElementsByClassName('co-content-wrap')[0].style.opacity = '1'
    }

    return (
      <>
        <Helmet>
          <title>
            Me contacter | MLgweb.fr | Création de sites internets et de logos.
          </title>
        </Helmet>
        <object
          type='image/svg+xml'
          data={logoTop}
          className='logo-top'
          onLoad={gridAnim}
        >
          svg
        </object>
        <div className='bottom-title' id='bot-title'>
          <object
            type='image/svg+xml'
            data={bottomTitle}
            className='bottom-title-img'
            onLoad={bTitle}
          >
            svg
          </object>
        </div>
        <div className='co-content-wrap'>
          <div className='grid-container' id='grid1'>
            <div className='grid-title'>
              <span>Formulaire de contact</span>
            </div>
            <div className='grid-content'>
              <form
                id='contact-form'
                onSubmit={this.handleSubmit.bind(this)}
                method='POST'
                autocomplete='off'
              >
                <fieldset className='ui-input'>
                  <input
                    type='text'
                    className='textbox'
                    id='message'
                    value={this.state.message}
                    onChange={this.onMessageChange.bind(this)}
                  />
                  <label htmlFor='message'>
                    <span>Vos nom et prénom</span>
                  </label>
                  <span className='contact-form-error' id='message-er'>
                    {this.state.errora.message}
                  </span>
                </fieldset>
                <fieldset className='ui-input'>
                  <input
                    type='text'
                    className='textbox'
                    id='phone'
                    value={this.state.phone}
                    onChange={this.onPhoneChange.bind(this)}
                  />
                  <label htmlFor='phone'>
                    <span>Votre numéro de téléphone</span>
                  </label>
                  <span className='contact-form-error' id='phone-er'>
                    {this.state.errora.phone}
                  </span>
                </fieldset>
                <fieldset className='ui-input'>
                  <input
                    type='email'
                    className='textbox'
                    id='email'
                    aria-describedby='emailHelp'
                    value={this.state.email}
                    onChange={this.onEmailChange.bind(this)}
                  />
                  <label htmlFor='email'>
                    <span>Votre adresse email</span>
                  </label>
                  <span className='contact-form-error' id='email-er'>
                    {this.state.errora.email}
                  </span>
                </fieldset>
                <fieldset className='ui-input'>
                  <textarea
                    className='textbox'
                    rows='5'
                    id='name'
                    value={this.state.name}
                    onChange={this.onNameChange.bind(this)}
                  />
                  <label htmlFor='name'>
                    <span>Votre message</span>
                  </label>
                  <span className='contact-form-error' id='name-er'>
                    {this.state.errora.name}
                  </span>
                </fieldset>
                <button type='submit' className='form-btn'>
                  Envoyer
                </button>
                <br />
              </form>
            </div>
            <div className='grid-par'></div>
            <div className='grid-par2'></div>
            <div className='grid-par3'></div>
            <div className='grid-par4'></div>
            <div className='grid-par5'></div>
            <div className='grid-par6'></div>
          </div>
        </div>
      </>
    )
  }
  onNameChange(event) {
    this.setState({ name: event.target.value })
  }
  onPhoneChange(event) {
    this.setState({ phone: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
}

export default Contact
