import React from 'react'
import bottomTitle from '../assets/img/references.svg'
import '../assets/css/references.css'
import logoTop from '../assets/img/logo.svg'
import { Helmet } from 'react-helmet-async'

function References() {
  const bTitle = () => {
    document.getElementById('bot-title').style.bottom = '0'
  }

  return (
    <>
      <Helmet>
        <title>
          Mes références | MLgweb.fr | Création de sites internets et de logos.
        </title>
      </Helmet>
      <object type='image/svg+xml' data={logoTop} className='logo-top'>
        svg
      </object>
      <div className='bottom-title' id='bot-title'>
        <object
          type='image/svg+xml'
          data={bottomTitle}
          id='bot-title'
          className='bottom-title-img'
          onLoad={bTitle}
        >
          svg
        </object>
      </div>
    </>
  )
}

export default References
