import React from 'react'
import '../assets/css/navigation.css'
import { Link } from 'react-router-dom'

function Navigation() {
  const showMenu = (n) => {
    const menuHamb = document.getElementsByClassName('hamburger')
    const menuNav = document.getElementById('nav')
    const mItem = document.getElementsByClassName('nav-item-deco-b')[n]

    menuHamb[0].classList.remove('is-active')
    menuNav.style.transitionDelay = '1.3s'
    menuNav.style.left = '100%'
    mItem.style.left = '100%'
  }

  // const mOverIn = (n) => {
  //   const mOverInItem = document.getElementsByClassName('nav-item-deco-b')[n];
  //   mOverInItem.style.left = '100%';
  // };
  // const mOverOut = (n) => {
  //   const mOverOutItem = document.getElementsByClassName('nav-item-deco-b')[n];
  //   mOverOutItem.style.left = '0%';
  // };
  // onMouseOver={() => mOverIn(0)} onMouseOut={() => mOverOut(0)}

  return (
    <div id='nav'>
      <ul className='nav-list'>
        <li onClick={() => showMenu(0)}>
          <Link to='/'>
            <button className='nav-item'>
              <span className='nav-item-deco-a'>[</span>
              <span className='nav-item-deco-b'>]</span>
              Accueil
            </button>
          </Link>
        </li>
        <li onClick={() => showMenu(1)}>
          <Link to='/prestations'>
            <button className='nav-item'>
              <span className='nav-item-deco-a'>[</span>
              <span className='nav-item-deco-b'>]</span>
              Prestations
            </button>
          </Link>
        </li>
        <li onClick={() => showMenu(2)}>
          <Link to='/references'>
            <button className='nav-item'>
              <span className='nav-item-deco-a'>[</span>
              <span className='nav-item-deco-b'>]</span>
              Références
            </button>
          </Link>
        </li>
        <li onClick={() => showMenu(3)}>
          <Link to='/contact'>
            <button className='nav-item'>
              <span className='nav-item-deco-a'>[</span>
              <span className='nav-item-deco-b'>]</span>
              Contact
            </button>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Navigation
